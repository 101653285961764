<template>
	<div :class="['XzAuditUserInfo',{'info-active' : navIndex == 2 }]">
		<w-navTab titleText="个人信息"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="header" >
			<div class="header-content" v-if="isTopShow == false">
				<div class="header-content-title">
					<div class="header-title-icon">
						<img src="@/assets/img/u_icon104.png" alt="">
					</div>
					<div class="header-title-right">
						<div class="title-right-title">义警服务证</div>
						<div class="title-right-text">{{userInfo.district_name}}平安义警协会</div>
					</div>
				</div>
				<div class="header-content-middle">
					<div class="header-middle-item">
						<div class="middle-item-lable">姓名/Name</div>
						<div class="middle-item-text">{{userInfo.name}}</div>
					</div>
					<div class="header-middle-item">
						<div class="middle-item-lable">性别/Sex</div>
						<div class="middle-item-text">{{userInfo.sex == 1 ? '男' : '女'}}</div>
					</div>
					<div class="header-middle-item">
						<div class="middle-item-lable">地区/Add</div>
						<div class="middle-item-text">信州区</div>
					</div>
				</div>
				<div class="header-middle-item">
					<div class="middle-item-lable">义警编号/No.</div>
					<div class="middle-item-text lable-active">{{userInfo.police_number}}</div>
				</div>
			</div>
			<div class="header-box" v-else>
				<div class="header-box-icon">
					<img src="@/assets/img/u_icon104.png" alt="">
				</div>
				<div class="header-box-title">
					奉献 友爱 互助 进步
				</div>
				<div class="header-box-time">
					有效期：{{validTime.start_ime}} 至 {{validTime.end_time}}
				</div>
			</div>
			<div class="headert-content-right">
				<div class="content-right-refresh" @click="isTopShow = !isTopShow">
					<img src="@/assets/img/u_icon109.png" alt="">
				</div>
				<div class="content-right-avatar">
					<div class="right-avatar-img" v-if="isTopShow == false">
						<img :src="userInfo.avatar" alt="" />
						<!-- <img v-else :src="userInfo.police_photo" alt="" /> -->
					</div>
					<div class="right-avatar-text" v-if="isTopShow == false">{{userInfo.duty}}</div>
				</div>
				<!-- <div class="content-right-share">
					<div class="right-share-icon">
						<img src="@/assets/img/u_icon101.png" alt="">
					</div>
					<div class="right-share-text" @click="$router.push({ name: 'LevelDescriptionShare' })">分享
					</div>
				</div> -->
			</div>
		</div>
		<div class="body">
			<div class="body-nav" >
				<div :class="['body-nav-item',{'item-active': navIndex == index}]" v-for="(item,index) in navList"
					:key="index" @click="onClickNav(index)">
					{{item.title}}
				</div>
			</div>
			<!-- 基本信息 -->
			<div class="body-content" v-if="navIndex == 0">
				<div class="body-content-list">
					<div class="body-list-item">
						<div class="body-item-label">姓名</div>
						<div class="body-item-text">{{userInfo.name}}</div>
					</div>
					<div class="body-list-item">
						<div class="body-item-label">性别</div>
						<div class="body-item-text">{{userInfo.sex == 1 ? '男' : '女'}}</div>
					</div>
					<div class="body-list-item">
						<div class="body-item-label">身高</div>
						<div class="body-item-text">{{userInfo.height}}cm</div>
					</div>
					<div class="body-list-item">
						<div class="body-item-label">体重</div>
						<div class="body-item-text">{{userInfo.weight}}kg</div>
					</div>
					<div class="body-list-item">
						<div class="body-item-label">血型</div>
						<div class="body-item-text">{{getTinyint(userInfo.blood_type)}}</div>
					</div>
					<div class="body-list-item">
						<div class="body-item-label">党员</div>
						<div class="body-item-text">{{userInfo.party_member == 1 ? '是' : '否'}}</div>
					</div>
					<div class="body-list-item">
						<div class="body-item-label">退伍军人</div>
						<div class="body-item-text">{{userInfo.veteran == 1 ? '是' : '否'}}</div>
					</div>
					<div class="body-list-item">
						<div class="body-item-label">工作单位</div>
						<div class="body-item-text">{{userInfo.company}}</div>
					</div>
					<!-- <div class="body-list-item">
						<div class="body-item-label">出生年月</div>
						<div class="body-item-text">{{userInfo.birthday}}</div>
					</div> -->
					<div class="body-list-item">
						<div class="body-item-label">身份证号</div>
						<div class="body-item-text">{{userInfo.id_card}}</div>
					</div>
					<div class="body-list-item">
						<div class="body-item-label">手机号</div>
						<div class="body-item-text">{{userInfo.telephone}}</div>
					</div>
					<!-- <div class="body-list-item">
						<div class="body-item-label">加入时间</div>
						<div class="body-item-text">{{userInfo.create_time}}</div>
					</div> -->
				</div>
			</div>
			<!-- 活动经历图表 -->
			<div class="body-content-chat-box" v-if="navIndex == 1">
				<div class="body-content-chart" :id="id"></div>
				<div class="content-chart-total">
					<div class="chart-total-number">{{typeTotal}}</div>
					<div class="chart-total-text">总数</div>
				</div>
			</div>
			<!-- 活动经历列表 -->
			<div class="body-content" v-if="navIndex == 1">
				<div class="body-content-undergo">
					<div class="body-undergo-title">
						<div class="undergo-title-left">
							<span>累计次数：</span>{{total}}
						</div>
						<div class="undergo-title-right">
							<div class="undergo-right-item" @click="isStatusShow = true">
								{{statusText}}<van-icon style="margin-left: 2px;" name="arrow-down" />
							</div>
							<div class="undergo-right-item" @click="isTimeShow = true">
								{{timeText}}<van-icon style="margin-left: 2px;" name="arrow-down" />
							</div>
						</div>
					</div>
					<div class="body-undergo-container">
						<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty"
							@onLoad="getRecord">
							<div class="body-undergo-list" v-for="(item,index) in recordList">
								<div class="undergo-list-item">
									<div class="undergo-list-title">{{item.title}}</div>
									<div class="undergo-list-number">+{{item.love_value}}</div>
								</div>
								<div class="undergo-list-item">
									<div class="undergo-list-text">{{item.create_date}}</div>
									<div class="undergo-list-text">爱心值</div>
								</div>
							</div>
						</VantList>
					</div>
				</div>
			</div>
			<!-- 数据统计 -->
			<div class="body-content-data" v-if="navIndex == 2">
				<div class="body-data-item">
					<div class="body-content-title">
						<div class="content-title-left">服务统计</div>
					</div>
					<div class="body-content-service">
						<div class="content-service-item">
							<div class="service-item-title">活动打卡</div>
							<div class="service-item-number color-red">
								{{service.register_count}}<span>次</span>
							</div>
						</div>
						<div class="content-service-item">
							<div class="service-item-title">服务时长</div>
							<div class="service-item-number color-green">
								{{getMath(service.service_time)}}<span>时</span>
							</div>
						</div>
						<div class="content-service-item">
							<div class="service-item-title">获得爱心值</div>
							<div class="service-item-number color-blue">
								{{service.love_value}}<span>个</span>
							</div>
						</div>
					</div>
				</div>

				<div class="body-data-item">
					<div class="body-content-title">
						<div class="content-title-left">年度统计</div>
						<div class="content-title-right" @click="isYearTimeShow = true">
							{{timeYear}}年<van-icon style="margin-left: 2px;" name="arrow-down" />
						</div>
					</div>
					<div class="body-content-annual">
						<div class="content-annual">
							<div class="content-annual-item">
								<div class="annual-item-header">
									<div class="annual-item-icon">
										<img src="@/assets/img/u_icon105.png" alt="">
									</div>
									<div class="annual-item-title">
										活动打卡<span>/次</span>
									</div>
								</div>
								<div class="annual-item-number">{{annual.register_count}}</div>
							</div>
							<div class="content-annual-item">
								<div class="annual-item-header">
									<div class="annual-item-icon">
										<img src="@/assets/img/u_icon106.png" alt="">
									</div>
									<div class="annual-item-title">
										服务时长<span>/小时</span>
									</div>
								</div>
								<div class="annual-item-number">{{getMath(annual.service_time)}}</div>
							</div>
						</div>
						<div class="content-annual">
							<div class="content-annual-item">
								<div class="annual-item-header">
									<div class="annual-item-icon">
										<img src="@/assets/img/u_icon107.png" alt="">
									</div>
									<div class="annual-item-title">
										爱心值<span>/个</span>
									</div>
								</div>
								<div class="annual-item-number">{{annual.love_value}}</div>
							</div>
							<div class="content-annual-item">
								<div class="annual-item-header">
									<div class="annual-item-icon">
										<img src="@/assets/img/u_icon108.png" alt="">
									</div>
									<div class="annual-item-title">
										签到天数<span>/天</span>
									</div>
								</div>
								<div class="annual-item-number">{{annual.signup_count}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<van-popup v-model="isStatusShow" position="bottom">
			<van-picker title="标题" show-toolbar :default-index="statusIndex" value-key="group_name" :columns="columns"
				@confirm="onConfirmStatus" @cancel="isStatusShow = false" />
		</van-popup>

		<van-popup v-model="isTimeShow" position="bottom">
			<van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" :min-date="minDate"
				:max-date="maxDate" @confirm="confirmTime" @cancel="isTimeShow = false" />
		</van-popup>

		<van-popup v-model="isYearTimeShow" position="bottom">
			<van-picker title="标题" show-toolbar :default-index="yearTimeIndex" :columns="yearList"
				@confirm="confirYearMTime" @cancel="isYearTimeShow = false" />
		</van-popup>
	</div>
</template>

<script>
	import User from '@/api/user';
	import WTab from '@/components/Tab';
	import VantList from '@/components/VantList';
	import LoadingPage from '@/components/LoadingPage';
	import Moment from '@/vendor/moment';
	import VantVendor from '@/vendor/vant';
	import * as echarts from 'echarts'

	export default {
		inject: ['className', 'districtType', 'themeColor'],
		name: 'UserInfo',
		data() {
			return {
				isTopShow: false,
				userId: '',
				loadingPage: true,
				loading: false,
				finished: false,
				isEmpty: false,

				userInfo: '',
				paramsPage: 1,
				paramsLimit: 10,

				navIndex: 0,
				navList: [{
					id: 1,
					title: '基本信息'
				}
				// , {
				// 	id: 2,
				// 	title: '活动经历'
				// }, {
				// 	id: 3,
				// 	title: '数据统计'
				// },
				],

				statusIndex: 0,
				statusText: '全部',
				timeText: '全部',
				timeMonth: '',
				isStatusShow: false,
				isTimeShow: false,
				minDate: new Date(2022, 0, 1),
				maxDate: new Date(2050, 10, 1),
				currentDate: new Date(2022, 0, 17),
				columns: [],
				recordList: [],
				statistics: '',
				groupId: '',
				total: '',

				id: 'main',
				service: '',
				annual: '',
				chart: null,
				typeList: [],
				columns: [],

				yearList: ['2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033',
					'2034', '2035', '2036', '2037', '2038', '2039', '2040', '2041', '2042', '2043', '2044', '2045',
					'2046', '2047', '2048', '2049', '2050'
				],
				timeYear: "",
				yearTimeIndex: 0,
				isYearTimeShow: false,
				validTime: '',
				typeTotal: ''
			};
		},
		created() {
			this.userId = this.$route.params.id
			this.timeText = Moment(new Date()).format('YYYY-MM')
			this.timeYear = Moment().format('YYYY')
			this.timeMonth = Moment(new Date()).format('YYYY-MM')
			// this.getActivityGroup()
			this.getUserInfo()
			// this.getStatistics()
			
			// this.getService()
			// this.getAnnual()
		},
		methods: {
			// 服务统计
			getService() {

				let params = {
					user_id: this.userId
				}

				User.service(params)
					.then(result => {
						this.service = result.data
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			// 活动类型统计
			getType() {

				let params = {
					user_id: this.userId
				}

				User.type(params)
					.then(result => {
						let list = []
						result.data.list.forEach(item => {

							let params = {
								value: item.total,
								name: item.group_name + '(' + parseInt(item.total) + ')'
							}
							list.push(params)
						})
						this.typeList = list
						this.typeTotal = result.data.total
						this.myChart();
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			// 年度统计
			getAnnual() {
				let params = {
					year: this.timeYear,
					user_id: this.userId
				}
				User.annual(params)
					.then(result => {
						this.annual = result.data
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			// 创建图表
			myChart() {
				// 获取节点
				this.chart = echarts.init(document.getElementById(this.id));
				// 配置项
				var option = {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						orient: 'vertical',
						top: 20,
						right: 10,
						itemWidth: 12,
						itemHeight: 12,
						textStyle: {
							color: '#3A3B3E',
							fontSize: 13,
						},
					},
					color: ['#FD5461', '#7964FF', '#3AA0FF', '#FE8C46', '#2EDFFF', '#4DDA77', '#FFCD3A', '#435188'],
					series: [{
						left: '-65%',
						type: 'pie',
						radius: ['45%', '65%'],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: 'center'
						},
						labelLine: {
							show: false
						},
						data: this.typeList
					}]
				};

				this.chart.setOption(option);
			},
			getCreateTime(value) {
				return Moment(value).format('YYYY-MM-DD')
			},
			onClickNav(index) {
				this.navIndex = index
				if (index == 1) {
					this.getRecord()
					this.getType()
				} else if (index != 1 && this.chart != null) {
					this.chart.dispose()
				}
			},
			// 活动经历列表
			getRecord() {
				this.loading = true;

				let params = {
					page: this.paramsPage,
					limit: this.paramsLimit,
					group_id: this.groupId,
					month: this.timeMonth,
					user_id: this.userId
				}

				User.activityRecord(params)
					.then(result => {
						this.paramsPage++;

						this.recordList = this.recordList.concat(result.data.list);

						this.total = result.data.total

						this.loading = false;

						if (result.data.total <= 0) {
							this.isEmpty = true;
						}

						if (this.recordList.length >= result.data.total) {
							this.finished = true;
						}
					})
					.catch(error => {
						console.log(error);
					});
			},
			// 获取数据统计
			getStatistics() {

				let params = {
					user_id: this.userId
				}

				User.statistics(params)
					.then(result => {
						this.statistics = result.data
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			// 活动经历分类
			getActivityGroup() {
				User.activityGroup()
					.then(result => {
						this.columns = result.data
						let params = {
							id: 0,
							group_name: '全部'
						}
						this.columns.unshift(params)
						this.groupId = this.columns[0].id
						this.statusText = this.columns[0].group_name
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			// 获取用户信息
			getUserInfo() {
				
				let params = {
					apply_id: this.userId
				}
				
				User.xzAuditInfo(params)
					.then(result => {
						this.userInfo = result.data;
						this.validTime = result.data.valid_time
						this.loadingPage = false;
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			// 转小时
			getMath(min) {
				let time = min / 60
				return time.toFixed(2);
			},
			getTinyint(type) {
				let text = {1:'A',2:'B',3:'AB',4:'O',5:'RH'}
				return text[type]
			},
			onConfirmStatus(e, value) {
				this.paramsPage = 1
				this.recordList = []
				this.statusIndex = value
				this.statusText = e.group_name
				this.groupId = e.id
				this.isStatusShow = false
				this.finished = false
				this.isEmpty = false
				this.getRecord()
			},
			confirmTime(e) {
				this.paramsPage = 1
				this.recordList = []
				this.isTimeShow = false
				this.timeText = Moment(e).format('YYYY-MM');
				this.timeMonth = Moment(e).format('YYYY-MM')
				this.finished = false
				this.isEmpty = false
				this.getRecord()
			},
			confirYearMTime(e, index) {
				this.isYearTimeShow = false
				this.yearTimeIndex = index
				this.timeYear = Moment(new Date(e)).format('YYYY');
				this.getAnnual()
			}
		},
		components: {
			WTab,
			VantList,
			LoadingPage,
		}
	};
</script>

<style scoped lang="less">
	.XzAuditUserInfo {
		width: 100%;
		min-height: 100vh;
		background: #fff;

		&.info-active {
			background: #F4F5F9 !important;
		}

		.header {
			padding: 16px 9px 8px;
			box-sizing: border-box;
			position: relative;

			.header-content {
				padding: 29px 23px;
				width: 357px;
				height: 211px;
				background-image: url('../../../assets/img/u_icon103.png');
				background-size: cover;
				box-sizing: border-box;

				.header-content-title {
					display: flex;
					align-items: center;

					.header-title-icon {
						width: 42px;
						height: 42px;
						min-width: 42px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.header-title-right {
						padding-left: 8px;

						.title-right-title {
							color: #020917;
							font-size: 17px;
							font-weight: bold;
							line-height: 20px;
						}

						.title-right-text {
							color: #333;
							margin-top: 4px;
							font-size: 12px;
						}
					}
				}

				.header-middle-item {
					margin-right: 24px;

					.middle-item-lable {
						color: #333;
						font-size: 12px;
						line-height: 18px;
					}

					.middle-item-text {
						color: #020917;
						font-size: 15px;
						margin-top: 4px;
						font-weight: bold;
						line-height: 23px;

						&.lable-active {
							font-size: 18px;
						}
					}
				}

				.header-content-middle {
					margin: 16px 0 12px;
					display: flex;
					align-items: center;
				}

			}

			.header-box {
				padding: 54px 23px 0;
				width: 357px;
				height: 211px;
				background-image: url('../../../assets/img/u_icon115.png');
				background-size: cover;
				box-sizing: border-box;
				text-align: center;

				.header-box-icon {
					width: 42px;
					height: 42px;
					margin: 0 auto;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.header-box-title {
					color: #020917;
					font-size: 20px;
					line-height: 24px;
					margin-top: 16px;
				}

				.header-box-time {
					margin-top: 8px;
					color: #333;
					font-size: 13px;
					line-height: 20px;
				}
			}

			.headert-content-right {
				position: absolute;
				top: 31px;
				right: 16px;

				.content-right-refresh {
					padding: 1px 11px;
					margin-left: 41px;
					width: 42px;
					height: 22px;
					border-radius: 0px 16px;
					background: rgba(0, 0, 0, 0.20);
					box-sizing: border-box;

					img {
						width: 20px;
						height: 20px;
						object-fit: cover;
					}
				}

				.content-right-avatar {
					height: 110px;
					margin-right: 20px;
					margin: 10px 20px 0 0;

					.right-avatar-img {
						width: 63px;
						height: 86px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.right-avatar-text {
						width: 63px;
						min-width: 63px;
						color: #FFF;
						font-size: 14px;
						line-height: 16px;
						margin-top: 8px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.content-right-share {
					padding: 2px 0;
					width: 54px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 20px;
					background: rgba(0, 0, 0, 0.20);
					box-sizing: border-box;
					margin: 24px 0 0 19px;

					.right-share-icon {
						width: 12px;
						height: 12px;
						margin-right: 2px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.right-share-text {
						color: #FFF;
						font-size: 12px;
					}
				}
			}

			.header-nav {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 20px;

				.header-nav-item {
					width: 72px;
					text-align: center;
					color: #9F9EA7;
					font-size: 16px;
					line-height: 20px;
					margin: 0 16px;

					&::after {
						content: '';
						display: block;
						width: 20px;
						height: 2px;
						border-radius: 23px;
						background: #FFF;
						margin: 6px auto 0;
					}

					&.item-active {
						color: #3A3B3E;
						font-size: 18px;
						font-weight: bold;

						&::after {
							content: '';
							display: block;
							width: 20px;
							height: 2px;
							border-radius: 23px;
							background: #37F;
							margin: 6px auto 0;
						}
					}
				}
			}
		}

		.body {
			padding: 16px 16px 18px;
			box-sizing: border-box;

			.body-nav {
				display: flex;
				justify-content: center;
				align-items: center;

				.body-nav-item {
					text-align: center;
					color: #9F9EA7;
					font-size: 16px;
					line-height: 20px;
					margin: 0 16px;

					&::after {
						content: '';
						display: block;
						width: 20px;
						height: 2px;
						border-radius: 23px;
						background: initial;
						margin: 6px auto 0;
					}

					&.item-active {
						color: #3A3B3E;
						font-size: 18px;
						font-weight: bold;

						&::after {
							content: '';
							display: block;
							width: 20px;
							height: 2px;
							border-radius: 23px;
							background: #37F;
							margin: 6px auto 0;
						}
					}
				}
			}

			.body-content {
				padding: 12px 10px;
				border-radius: 8px;
				background: #F4F9FF;
				box-sizing: border-box;
				margin-top: 12px;

				.body-content-list {
					padding: 0 10px;
					border-radius: 8px;
					background: #FFF;
					box-sizing: border-box;

					.body-list-item {
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 15px;
						line-height: 24px;
						padding: 12px 0;
						border-bottom: 1px solid rgba(34, 34, 34, .1);
						box-sizing: border-box;

						.body-item-label {
							color: #A1A5B1;
						}

						.body-item-text {
							color: #3A3B3E;
						}

						&.body-list-item:last-child {
							border: 0;
						}
					}
				}

				.body-content-undergo {
					.body-undergo-title {
						display: flex;
						justify-content: space-between;
						align-items: center;

						.undergo-title-left {
							display: flex;
							align-items: center;
							color: #222;
							font-size: 18px;
							line-height: 26px;

							span {
								font-size: 12px;
							}
						}

						.undergo-title-right {
							display: flex;
							align-items: center;

							.undergo-right-item {
								height: 25px;
								line-height: 25px;
								padding: 0 3px;
								color: #4E586F;
								font-size: 12px;
								border-radius: 4px;
								border: 1px solid #E0E0E3;
								background: #FFF;
								box-sizing: border-box;
								margin-right: 8px;

								&.undergo-right-item:last-child {
									margin-right: 0;
								}
							}
						}
					}

					.body-undergo-container {
						.body-undergo-list {
							margin-top: 8px;
							border-radius: 4px;
							background: #FFF;
							padding: 8px;
							box-sizing: border-box;

							&.body-undergo-list:first-child {
								margin-top: 12px;
							}

							.undergo-list-item {
								display: flex;
								justify-content: space-between;
								align-items: center;
								margin-bottom: 4px;

								&.undergo-list-item:last-child {
									margin-bottom: 0;
								}

								.undergo-list-title {
									width: 250px;
									color: #222;
									font-size: 16px;
									line-height: 24px;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
								}

								.undergo-list-number {
									color: #FF6969;
									font-size: 18px;
									line-height: 24px;
								}

								.undergo-list-text {
									color: #999;
									font-size: 13px;
									line-height: 24px;
								}
							}
						}
					}
				}
			}

			.body-content-chat-box {
				position: relative;

				.body-content-chart {
					margin: 12px 0 0;
					width: 100%;
					border-radius: 12px;
					background: #FFF;
					height: 150px;
					border-radius: 12px;
					background: #F4F9FF;
				}

				.content-chart-total {
					width: 70px;
					position: absolute;
					left: 25px;
					bottom: 50px;

					.chart-total-number {
						color: #FD5461;
						text-align: center;
						font-size: 18px;
					}

					.chart-total-text {
						color: #3A3B3E;
						text-align: center;
						font-size: 12px;
						line-height: 18px;
					}
				}
			}

			.body-content-data {
				margin-top: 8px;

				.body-data-list {
					display: flex;
					justify-content: space-between;
					align-items: center;

					&.body-data-list:first-child {
						margin: 4px 0 16px;
					}

					&.body-data-list:last-child {
						margin-bottom: 4px;
					}

					.body-data-item {
						width: 159px;
						padding: 8px 10px;
						box-sizing: border-box;
						border-radius: 8px;
						background: #FFF;
						box-shadow: 0px 0px 4px 1px rgba(221, 221, 221, 0.50);

						.data-item-title {
							color: #3A3B3E;
							font-size: 14px;
							margin-bottom: 4px;
						}

						.data-item-bottom {
							display: flex;
							justify-content: space-between;
							align-items: center;

							.data-bottom-number {
								font-size: 28px;
								line-height: 34px;

								span {
									font-size: 13px;
									line-height: 22px;
								}

								&.color-red {
									color: #FF7A7B;
								}

								&.color-green {
									color: #3AE396;
								}

								&.color-blue {
									color: #57C2FF;
								}

								&.color-purple {
									color: #B447F3;
								}
							}

							.data-bottom-icon {
								width: 15px;
								height: 17px;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}
						}
					}
				}

				.body-data-item {
					position: relative;

					.body-content-title {
						display: flex;
						justify-content: space-between;
						align-items: center;

						.content-title-left {
							color: #3A3B3E;
							font-size: 18px;
							font-weight: bold;
							line-height: 20px;

							&::after {
								content: '';
								display: block;
								width: 112px;
								height: 6px;
								background: linear-gradient(90deg, #37F 0.02%, rgba(51, 119, 255, 0.00) 100.86%);
								margin-top: -4px;
								margin-left: -2px;
							}
						}

						.content-title-right {
							color: #A1A5B1;
							font-size: 14px;
							line-height: 20px;
						}
					}

					.body-content-service {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin: 20px 0 28px;

						.content-service-item {
							padding: 12px 8px;
							width: 108px;
							border-radius: 8px;
							background: #FFF;
							box-sizing: border-box;

							.service-item-title {
								color: #A1A5B1;
								font-size: 14px;
								line-height: 21px;
							}

							.service-item-number {
								font-size: 20px;
								margin-top: 16px;

								span {
									font-size: 13px;
									line-height: 22px;
								}

								&.color-red {
									color: #FF7A7B;
								}

								&.color-green {
									color: #3AE396;
								}

								&.color-blue {
									color: #57C2FF;
								}
							}
						}
					}



					.body-content-annual {
						margin-top: 20px;

						.content-annual {
							display: flex;
							justify-content: space-between;
							align-items: center;

							.content-annual-item {
								width: 165px;
								border-radius: 8px;
								background: #FFF;
								padding: 8px 16px;
								box-sizing: border-box;
								margin-bottom: 12px;

								.annual-item-header {
									display: flex;
									align-items: center;

									.annual-item-icon {
										width: 28px;
										height: 28px;
										margin-right: 8px;

										img {
											width: 100%;
											height: 100%;
											object-fit: cover;
										}
									}

									.annual-item-title {
										color: #3A3B3E;
										font-size: 14px;
										line-height: 21px;

										span {
											color: #A1A5B1;
											font-size: 12px;
											line-height: 22px;
										}
									}
								}

								.annual-item-number {
									color: #3A3B3E;
									font-size: 24px;
									line-height: 29px;
									margin-top: 4px;
								}
							}
						}
					}
				}
			}
		}
	}
</style>